<template>
  <router-view/>
</template>

<script setup lang="ts">

</script>

<style lang="less">
body {
  background-color: whitesmoke !important;
  background-image: url(/public/bg1.svg);
}

.page-caption {
  padding: 20px;
  font-weight: 900;
  font-size: 2em;
}
</style>
